import moment from 'moment';
import React from 'react';
import { Radar } from 'react-chartjs-2';

// Function to generate a random color in rgba format
const getRandomColor = () => {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  return {
    backgroundColor: `rgba(${r}, ${g}, ${b}, 0)`,
    borderColor: `rgba(${r}, ${g}, ${b}, 1)`,
  };
};

const RadarChart = ({ data }) => {
  if (!data || !data.length) {
    return <p>No data available</p>;
  }

  const labelsNames = [
    'Préparation  de la  visite', "Connaissance scientifique et l'environement", "Utilisation de l'ADV et remis de visite",
    "L'accroche", 'Le questionnement', 'Argumentaire', 'Points d\'accords', 'Respect Campagne marketing',
    'Ecoute', 'Traitemet des objections', 'Engagement', 'Qualité de la visite', 'Enquête pharmacies', 'Ciblage Médecin'
  ];

  const datasets = data.map((person) => {
    // Map the notation for each label in person.items
    const notationData = labelsNames.map((label) => {
      // Check if the item exists for the current label
      const item = Object.values(person.items).find((item) => item.title === label);
      return item ? parseInt(item.notation) || 0 : 0;
    });

    const colors = getRandomColor();

    // Create the label including the visitor name and duo_date
    const label = `(${moment(person.duo_date).format("YYYY-MM-DD")})`;

    return {
      label: label, // Include name and duo_date in the label
      data: notationData, // Map the notation values for the current person
      backgroundColor: colors.backgroundColor,
      borderColor: colors.borderColor,
      borderWidth: 3,
    };
  });

  // Chart configuration
  const chartData = {
    labels: labelsNames, // Use the provided label names
    datasets, // Use the datasets for each person with duo_date
  };

  const options = {
    scale: {
      ticks: { beginAtZero: true },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <div style={{ width: '100%', height: '800px' }}>
      <Radar data={chartData} options={options} />
    </div>
  );
};

export default RadarChart;
